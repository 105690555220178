import { useCallback, useEffect, useState } from 'react';
import { Voyager, voyagerIntrospectionQuery } from 'graphql-voyager';

type BaseMessage = { message: string; value: string };

export const App = () => {
  const [introspectionData, setIntrospectionData] = useState(null);
  const [ready, setReady] = useState(false);
  const [gqUrl, setGqUrl] = useState('http://localhost:8080/v1/graphql');
  const [token, setToken] = useState({
    'x-hasura-role': 'admin',
    'x-hasura-admin-secret': 'admin_secret',
  });
  const [framed, setFramed] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const initFrame = useCallback(() => {
    if (window.location !== window.parent.location) {
      setFramed(true);
    } else {
      setReady(true);
    }
  }, []);

  const syncReady = useCallback(() => {
    if (framed) {
      const message = { message: 'frame', value: 'ready' };
      window.parent.postMessage(message, '*');
    }
  }, [framed]);

  const syncReceive = useCallback(() => {
    const handler = (event: MessageEvent<BaseMessage>) => {
      try {
        const { message, value } = event.data;
        if (message === 'init') {
          const values = JSON.parse(value);
          setGqUrl(values.gqUrl);
          setToken(values.token);
          setReady(true);
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    const fetchIntrospectionData = async () => {
      try {
        const response = await fetch(gqUrl, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(framed ? { Authorization: `Bearer ${token}` } : { ...token }),
          },
          body: JSON.stringify({ query: voyagerIntrospectionQuery }),
        });
        const result = await response.json();
        if (result.errors && result.errors.length > 0) {
          setErrorMsg(result.errors[0].message);
        } else {
          setIntrospectionData(result);
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    if (ready) {
      fetchIntrospectionData();
    }
  }, [framed, gqUrl, ready, token]);

  useEffect(initFrame, [initFrame]);
  useEffect(syncReady, [syncReady]);
  useEffect(syncReceive, [syncReceive]);
  return (
    <>
      {errorMsg && (
        <div
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {errorMsg}
        </div>
      )}
      {introspectionData && (
        <Voyager
          introspection={introspectionData}
          displayOptions={{ skipRelay: false, showLeafFields: true }}
        />
      )}
    </>
  );
};
